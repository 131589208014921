import React from 'react'
import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'

class ProfileOptions extends React.Component {
  constructor(props) {
    super(props)

    this.id = JSON.parse(sessionStorage.getItem('userId'))

    this.state = {
      learnmode: false,
      newCategoryFocus: false,
      newCategoryValue: '',
      newTagFocus: false,
      newTagValue: '',
      categories: [],
      tags: []
    }
  }

  componentDidMount() {
    this.loadCategories()
    this.loadTags()
    this.loadTooltipsSettings()
  }

  async loadTooltipsSettings() {
    var user = await CmsHelper.get({
      type: 'users',
      id: this.id
    })
    this.setState({
      learnmode: user.show_tooltips
    })
  }

  async loadCategories() {
    var response = await CmsHelper.get({
      type: 'categories',
      sort: {
        category: 1
      }
    })
    var categories = []
    response.forEach(function(category, i) {
      categories.push({
        id: category._id,
        name: category.category
      })
    })
    this.setState({
      categories: categories
    })
  }

  async loadTags() {
    var response = await CmsHelper.get({
      type: 'tags',
      sort: {
        tag: 1
      }
    })
    var tags = []
    response.forEach(function(tag, i) {
      tags.push({
        id: tag._id,
        name: tag.tag
      })
    })
    this.setState({
      tags: tags
    })
  }

  async handleChangeLearnmode() {
    var learnmode = !this.state.learnmode
    await CmsHelper.put({
      type: 'users',
      id: this.id,
      body: JSON.stringify({
        show_tooltips: learnmode
      })
    })
    this.setState({
      learnmode: learnmode
    })
  }

  handleChangeNewCategory(e) {
    this.setState({
      newCategoryValue: e.target.value
    })
  }

  handleFocusNewCategory() {
    this.setState({
      newCategoryFocus: true
    })
  }

  handleBlurNewCategory() {
    this.setState({
      newCategoryFocus: false
    })
  }

  handleKeyPressNewCategory(e) {
    if(e.key === 'Enter' && e.target.value !== '') {
      this.addCategory()
      e.target.blur()
    }
  }

  handleChangeNewTag(e) {
    this.setState({
      newTagValue: e.target.value
    })
  }

  handleFocusNewTag() {
    this.setState({
      newTagFocus: true
    })
  }

  handleBlurNewTag() {
    this.setState({
      newTagFocus: false
    })
  }

  handleKeyPressNewTag(e) {
    if(e.key === 'Enter' && e.target.value !== '') {
      this.addTag()
      e.target.blur()
    }
  }

  async addCategory() {
    var category = await fetch(
      'https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/save/categories?token=1d40fd932ae1f0b9a197e1e7f2cdac',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            category: this.state.newCategoryValue
          }
        })
      }
    ).then(res => res.json())
    var categories = this.state.categories
    categories.push({
      id: category._id,
      name: this.state.newCategoryValue
    })
    this.setState({
      newCategoryFocus: false,
      newCategoryValue: '',
      categories: categories
    })
  }

  async addTag() {
    var tag = await fetch(
      'https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/save/tags?token=1d40fd932ae1f0b9a197e1e7f2cdac',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            tag: this.state.newTagValue
          }
        })
      }
    ).then(res => res.json())
    var tags = this.state.tags
    tags.push({
      id: tag._id,
      name: this.state.newTagValue
    })
    this.setState({
      newTagFocus: false,
      newTagValue: '',
      tags: tags
    })
  }

  handleDelete(args) {
    this.delete(args)
  }

  async delete(args) {
    await fetch('https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/remove/' + args.type + '?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: {
          _id: args.id
        }
      })
    })
    var array = this.state[args.type]
    var index
    array.forEach(function(element, i) {
      if(element.id == args.id) {
        index = i
      }
    })
    if(index !== undefined) {
      array.splice(index, 1);
    }
    if(args.type === 'categories') {
      this.setState({
        categories: array
      })
    } else if(args.type === 'tags') {
      this.setState({
        tags: array
      })
    }
  }

  async handleResetTooltips() {
    await CmsHelper.put({
      type: 'users',
      id: this.id,
      body: JSON.stringify({
        closed_tooltips: {}
      })
    })
    alert('Die Tooltips wurden zurück gesetzt')
  }

  render() {
    var learnmodeValue = this.state.learnmode
    var learnmodeNname = 'learnmode'
    return(
      <div id="profile-options">
        <div className="section">
          <h2>Optionen</h2>
          <div className="field">
            <div className="input input-switch">
              <div className="label">Lernmodus</div>
              <button
                className="reset-tooltips"
                onClick={this.handleResetTooltips.bind(this)}
              >
                zurücksetzen
              </button>
              <input
                type="checkbox"
                id={learnmodeNname}
                checked={learnmodeValue}
                onChange={this.handleChangeLearnmode.bind(this)}
              />
              <label htmlFor={learnmodeNname}>
                <div className="switch">
                  <div className="rail">
                    <div className="label label-on">An</div>
                    <div className="label label-off">Aus</div>
                  </div>
                  <div className="handle"></div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Kategorien</h2>
          <div className="list">
            {
              this.state.categories ?
                <ul>
                  {
                    this.state.categories.map((category, index) => {
                      return(
                        <li key={index}>
                          <div className="label">{category.name}</div>
                          <button
                            onClick={this.handleDelete.bind(this, {type: 'categories', id: category.id})}
                          >
                            <span className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8.553" height="10.53" viewBox="0 0 8.553 10.53">
                                <g transform="translate(0.003 0.001)">
                                  <path d="M222.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,222.645,154.7Zm0,0" transform="translate(-216.917 -150.889)" />
                                  <path d="M104.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,104.645,154.7Zm0,0" transform="translate(-101.827 -150.889)" />
                                  <path d="M.7,3.134V9.21a1.361,1.361,0,0,0,.362.938,1.214,1.214,0,0,0,.881.381H6.606a1.214,1.214,0,0,0,.881-.381,1.361,1.361,0,0,0,.362-.938V3.134a.942.942,0,0,0-.242-1.853H6.345V.973A.969.969,0,0,0,5.368,0H3.178A.969.969,0,0,0,2.2.973v.308H.939A.942.942,0,0,0,.7,3.134Zm5.909,6.9H1.94a.781.781,0,0,1-.75-.826V3.155H7.356V9.21A.781.781,0,0,1,6.606,10.036ZM2.695.973A.475.475,0,0,1,3.178.492h2.19a.475.475,0,0,1,.483.481v.308H2.695Zm-1.756.8H7.607a.444.444,0,0,1,0,.888H.939a.444.444,0,1,1,0-.888Zm0,0" transform="translate(0 0)" />
                                  <path d="M163.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,163.645,154.7Zm0,0" transform="translate(-159.372 -150.889)" />
                                </g>
                              </svg>
                            </span>
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
              :
                ''
            }
            <div className="add-li">
              <input
                className={this.state.newCategoryFocus ? 'focus' : ''}
                type="text"
                name="category"
                placeholder="Neue Kategorie"
                value={this.state.newCategoryValue}
                onChange={this.handleChangeNewCategory.bind(this)}
                onFocus={this.handleFocusNewCategory.bind(this)}
                onBlur={this.handleBlurNewCategory.bind(this)}
                onKeyPress={this.handleKeyPressNewCategory.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Tags</h2>
          <div className="list">
            {
              this.state.tags ?
                <ul>
                  {
                    this.state.tags.map((tag, index) => {
                      return(
                        <li key={index}>
                          <div className="label">{tag.name}</div>
                          <button
                            onClick={this.handleDelete.bind(this, {type: 'tags', id: tag.id})}
                          >
                            <span className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8.553" height="10.53" viewBox="0 0 8.553 10.53">
                                <g transform="translate(0.003 0.001)">
                                  <path d="M222.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,222.645,154.7Zm0,0" transform="translate(-216.917 -150.889)" />
                                  <path d="M104.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,104.645,154.7Zm0,0" transform="translate(-101.827 -150.889)" />
                                  <path d="M.7,3.134V9.21a1.361,1.361,0,0,0,.362.938,1.214,1.214,0,0,0,.881.381H6.606a1.214,1.214,0,0,0,.881-.381,1.361,1.361,0,0,0,.362-.938V3.134a.942.942,0,0,0-.242-1.853H6.345V.973A.969.969,0,0,0,5.368,0H3.178A.969.969,0,0,0,2.2.973v.308H.939A.942.942,0,0,0,.7,3.134Zm5.909,6.9H1.94a.781.781,0,0,1-.75-.826V3.155H7.356V9.21A.781.781,0,0,1,6.606,10.036ZM2.695.973A.475.475,0,0,1,3.178.492h2.19a.475.475,0,0,1,.483.481v.308H2.695Zm-1.756.8H7.607a.444.444,0,0,1,0,.888H.939a.444.444,0,1,1,0-.888Zm0,0" transform="translate(0 0)" />
                                  <path d="M163.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,163.645,154.7Zm0,0" transform="translate(-159.372 -150.889)" />
                                </g>
                              </svg>
                            </span>
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
              :
                ''
            }
            <div className="add-li">
              <input
                className={this.state.newTagFocus ? 'focus' : ''}
                type="text"
                name="category"
                placeholder="Neue Tags"
                value={this.state.newTagValue}
                onChange={this.handleChangeNewTag.bind(this)}
                onFocus={this.handleFocusNewTag.bind(this)}
                onBlur={this.handleBlurNewTag.bind(this)}
                onKeyPress={this.handleKeyPressNewTag.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfileOptions
