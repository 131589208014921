import React from 'react'
import {withRouter} from 'react-router'

import CmsHelper from '../../../helpers/Cms'
import ConfigHelper from '../../../helpers/Config'
import ScormExport from './scorm-export/ScormExport';

import ReactDOMServer from 'react-dom/server';

var FileSaver = require('file-saver');

var request = require('request');
var JSZip = require("jszip");

var prettifyXml = function(sourceXml)
{
    var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    var xsltDoc = new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.title = React.createRef()
    this.duration = React.createRef()
    this.format = React.createRef()

    this.staticFilesLists = {
      scripts: [
        'jquery.min.js',
        'jquery-ui.min.js',
        'draggable.bundle.legacy.min.js',
        'slick.min.js',
        'main.min.js'
      ],
      styles: [
        'main.min.css'
      ],
      icons: [
        'audio.svg',
        'quiz.png',
        'quiz.svg'
      ]
    }

    this.exportFormats = [];
    if(process.env.REACT_APP_EXPORT_LMS === 'true')  {
      this.exportFormats.push({
        value: 'fp-lms',
        label: 'FP.lms'
      })
    }
    if(process.env.REACT_APP_EXPORT_SCORM === 'true')  {
      this.exportFormats.push({
        value: 'scorm-1-2',
        label: 'SCORM 1.2'
      })
    }
    this.state = {
      exported: false,
      format: this.exportFormats[0].value
    }
  }

  getInputValue(name) {
    return this[name].current.value
  }

  getEndpoint() {
    return 'https://globalrailacademy.fp-eauthor.fp-server.com/cms'
  }

  async loadImageSrc(id) {
    var file
    if(id !== undefined && id !== '') {
      var response = await CmsHelper.get({
        type: 'attachments',
        id: id
      })
      file = response.file.path
    } else {
      var response = await CmsHelper.getSingleton({
        name: 'defaults'
      })
      file = response.placeholderImage.path
    }
    var image = CmsHelper.getImageUrl({
      file: file,
      width: 800,
      height: 450
    })
    return image
  }

  async exportWBTToWBT(args) {
    var imageSrc = await this.loadImageSrc(args.imageId)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("Access-Control-Allow-Origin", "*")
    myHeaders.append("Access-Control-Allow-Methods", "DELETE, PUT, POST, GET, OPTIONS")
    myHeaders.append("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
    myHeaders.append("Authorization", "Basic bWVkaWF3ZXJrOnF1dzl5Zmlx")
    var response2 = await fetch(
      'https://cors-anywhere.herokuapp.com/https://lms.aciso-academy.fp-server.com/api/wbts/' + args.id + '?image=' + encodeURIComponent(imageSrc) + '&title=' + args.title + '&duration=' + args.duration,
      {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
      }
    ).then(res => res.json())
    this.setState({
      exported: true
    })
  }

  handleExportWbt(e) {
    e.preventDefault()

    if(this.state.format == 'fp-lms') {
      this.exportWBTToWBT({
        id: this.props.wbtId,
        imageId: this.props.settings.image,
        title: this.getInputValue('title'),
        duration: this.getInputValue('duration')
      });
    } else {
      this.downloadSCORM12();
    }
  }

  async downloadSCORM12() {
    var scromName = this.getInputValue('title');

    var item = [];
    var resource = [];

    if(this.props.wbt.modules && this.props.wbt.modules.length > 1) {
      this.props.wbt.modules.map((module) => {
        var headline = scromName;
        if(module.settings.headline_show !== false && module.settings.headline != '') {
          headline = module.settings.headline;
        }
        item.push({
          _attributes: {
            identifier: module.id + '_item',
            identifierref: module.id + '_resource',
          },
          title: {
            _text: headline
          }
        });
        resource.push({
          _attributes: {
            identifier: module.id + '_resource',
            type: 'webcontent',
            'adlcp:scormtype': 'sco',
            href: 'res/' + module.id + '.html'
          },
          dependency: {
            _attributes: {
              identifierref: 'common_files'
            }
          }
        })
      });
    }

    resource.push({
      _attributes: {
        identifier: 'common_files',
        type: 'webcontent',
        'adlcp:scormtype': 'asset'
      },
      file: this.staticFilesLists.scripts.map((file) => {
        return {
          _attributes: {
            href: 'shared/' + file
          }
        }
      }).concat(this.staticFilesLists.styles.map((file) => {
        return {
          _attributes: {
            href: 'shared/' + file
          }
        }
      }).concat(this.staticFilesLists.icons.map((file) => {
        return {
          _attributes: {
            href: 'shared/' + file
          }
        }
      })))
    })

    var organization = {
      _attributes: {
        identifier: this.props.wbtId
      },
      title: {
        _text: scromName
      },
      item: item
    }

    var convert = require('xml-js');
    var json = {
      _declaration: {
        _attributes: {
          version: "1.0",
          standalone: "no"
        }
      },
      manifest: {
        _attributes: {
          identifier: this.props.wbtId,
          version: "1",
          xmlns: 'http://www.imsproject.org/xsd/imscp_rootv1p1p2',
          'xmlns:adlcp': 'http://www.adlnet.org/xsd/adlcp_rootv1p2',
          'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance'
        },
        metadata: {
          schema: {
            _text: 'ADL SCORM'
          },
          schemaversion: {
            _text: '1.2'
          }
        },
        organizations: {
          _attributes: {
            default: this.props.wbtId
          },
          organization: organization
        },
        resources: {
          resource: resource
        }
      }
    };
    var options = {compact: true};
    var imsmanifest = convert.json2xml(json, options);

    var imsCmlContent = this.getScormFile('ims_xml.xsd', '');
    var adlcpRootv1p2 = this.getScormFile('adlcp_rootv1p2.xsd', '');
    var imscpRootv1p1p2 = this.getScormFile('imscp_rootv1p1p2.xsd', '');
    var imsmdRootv1p2p1 = this.getScormFile('imsmd_rootv1p2p1.xsd', '');

    let zip = require('jszip')();
    zip.folder(scromName).file('imsmanifest.xml', imsmanifest);
    zip.folder(scromName).file('adlcp_rootv1p2.xsd', adlcpRootv1p2);
    zip.folder(scromName).file('ims_xml.xsd', imsCmlContent);
    zip.folder(scromName).file('imscp_rootv1p1p2.xsd', imscpRootv1p1p2);
    zip.folder(scromName).file('imsmd_rootv1p2p1.xsd', imsmdRootv1p2p1);
    this.staticFilesLists.scripts.map((file) => {
      var content = this.getScormFile(file, 'scripts/');
      zip.folder(scromName).folder('shared').folder('scripts').file(file, content);
    });
    this.staticFilesLists.styles.map((file) => {
      var content = this.getScormFile(file, 'styles/');
      zip.folder(scromName).folder('shared').folder('styles').file(file, content);
    });
    this.staticFilesLists.icons.map((file) => {
      var content = this.getScormFile(file, 'images/icons/');
      zip.folder(scromName).folder('shared').folder('images').folder('icons').file(file, content);
    });
    if(this.props.wbt.modules && this.props.wbt.modules.length > 1) {
      this.props.wbt.modules.map((module) => {
        /*this.staticFilesLists.icons.map((file) => {
          zip.folder(scromName).folder('shared').folder('media').folder('icons').file(file, content);
        });*/
      });
    }

    zip = await this.addScormModules(zip, scromName);

    zip.generateAsync({type:"blob"}).then(function (blob) {
      FileSaver.saveAs(blob, scromName + '.zip');
    }, function (err) {
      console.log(err)
      alert('Fehler!')
    });
  }

  addScormModules(zip, scromName) {
    var modules
    if(this.props.wbt.modules && this.props.wbt.modules.length > 0) {
      modules = this.props.wbt.modules.map(async (module, index) => {
        var contentBefore = '';
        var contentAfter = '';
        contentBefore += '<!DOCTYPE html>';
        contentBefore += '<html xmlns="http://www.w3.org/1999/xhtml" dir="ltr">';
          contentBefore += '<head>';
            contentBefore += '<title>' + scromName + '</title>';
            contentBefore += '<script>var apiHandle = null;function getAPIHandle() {if(apiHandle == null) {apiHandle = getAPI();}return apiHandle;}function getAPI() {var theAPI = findAPI(window);if((theAPI == null) && (window.opener != null) && (typeof(window.opener) != "undefined")) {theAPI = findAPI(window.opener);}return theAPI;}function findAPI(win) {var findAPITries = 0;while((win.API == null) && (win.parent != null) && (win.parent != win)) {findAPITries++;if(findAPITries > 7) {message("Error finding API -- too deeply nested.");return null;}win = win.parent;}return win.API;}function SetValue(name, value) {var api = getAPIHandle();return api.LMSSetValue(name, value);}function Getvalue(name) {var api = getAPIHandle();return api.LMSGetValue(name);}var api = getAPIHandle();if(api != null) {api.LMSInitialize("");}</script>';
          contentBefore += '</head>';
          contentBefore += '<body id="body" class="scorm">';
          contentAfter += '<style type="text/css" media="screen">@import url(../shared/styles/main.min.css);</style>';
          contentAfter += '<script src="../shared/scripts/jquery.min.js" type="text/javascript"></script>';
          contentAfter += '<script src="../shared/scripts/jquery-ui.min.js" type="text/javascript"></script>';
          contentAfter += '<script src="../shared/scripts/draggable.bundle.legacy.min.js" type="text/javascript"></script>';
          contentAfter += '<script src="../shared/scripts/slick.min.js" type="text/javascript"></script>';
          contentAfter += '<script src="../shared/scripts/main.min.js" type="text/javascript"></script>';
          contentAfter += '</body>';
        contentAfter += '</html>';
        var content = await this.getScormModuleContent(module);
        console.log(content);
        content = contentBefore + content + contentAfter;
        console.log(content);
        zip.folder(scromName).folder('res').file(module.id + '.html', content)
      });
    }
    if(modules === undefined) {
      return zip;
    } else {
      return Promise.all(modules).then(function(results) {
        return zip;
      })
    }
  }

  async getScormModuleContent(module) {
    var urls = await this.getUrls();
    var scormExport = new ScormExport(module, urls);
    var html = scormExport.render();
    return ReactDOMServer.renderToString(html)
  }

  async getUrls() {
    var urls = {};
    if(this.props.wbt.modules !== undefined) {
      var modules = this.props.wbt.modules.map((module) => {
        var elements = module.elements.map((element) => {
          var type = element.type
          var elementConfig = ConfigHelper.getElementConfig(type)
          if(elementConfig.settings.sections !== undefined) {
            var sections = elementConfig.settings.sections.map((section) => {
              if(section.fields !== undefined) {
                var fields = section.fields.map((sectionField) => {
                  if(sectionField.type == 'audio' || sectionField.type == 'image') {
                    if(element.settings[sectionField.name] != '') {
                      return fetch('https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/get/attachments?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
                        method: 'post',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          filter: {
                            _id: element.settings[sectionField.name]
                          },
                        })
                      }).then(response => response.json()).then(function(results) {
                        var src = CmsHelper.getDirectUrl({
                          file: results.entries[0].file.path
                        })
                        return urls[element.id + '_' + sectionField.name] = src
                      })
                    }
                  } else if(sectionField.type == 'repeater') {
                    if(sectionField.fields !== undefined) {
                      var rElements = Object.keys(element.settings[sectionField.name]).map((rElement, i) => {
                        var fieldField = sectionField.fields.map((sectionFieldField) => {
                          if(sectionFieldField.type == 'audio' || sectionFieldField.type == 'image') {
                            if(element.settings[sectionField.name][i].settings[sectionFieldField.name] != '') {
                              return fetch('https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/get/attachments?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
                                method: 'post',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                  filter: {
                                    _id: element.settings[sectionField.name][i].settings[sectionFieldField.name]
                                  },
                                })
                              }).then(response => response.json()).then(function(results) {
                                var src = CmsHelper.getDirectUrl({
                                  file: results.entries[0].file.path
                                })
                                return urls[element.id + '_' + sectionField.name + '_' + element.settings[sectionField.name][i].id + '_' + sectionFieldField.name] = src
                              })
                            }
                          }
                          /*if(sectionFieldField.type == 'text' || sectionFieldField.type == 'textarea' || sectionFieldField.type == 'wysiwyg') {
                            if(element.settings[sectionField.name][i].settings[sectionFieldField.name] != undefined) {
                              if(element.settings[sectionField.name][i].settings[sectionFieldField.name] != '') {
                                var field = {};
                                field.type = sectionFieldField.type;
                                field.name = '';
                                field.key = element.id + '_' + sectionField.name + '_' + element.settings[sectionField.name][i].id + '_' + sectionFieldField.name;
                                if(sectionFieldField.type == 'wysiwyg') {
                                  const rawContentState = element.settings[sectionField.name]
                                  const markup = draftToHtml(
                                    rawContentState
                                  );
                                  field.value = markup.replace(/<\/?[^>]+(>|$)/g, "");
                                } else {
                                  field.value = element.settings[sectionField.name][i].settings[sectionFieldField.name];
                                }
                                fields.push(field);
                              }
                            }
                          }*/
                        })
                        return Promise.all(fieldField).then(function(results) {
                        })
                      })
                      return Promise.all(rElements).then(function(results) {
                      })
                    }
                  }
                })
                return Promise.all(fields).then(function(results) {
                })
              }
            })
            return Promise.all(sections).then(function(results) {
            })
          }
        })
        return Promise.all(elements).then(function(results) {
        })
      });
    }
    return Promise.all(modules).then(function(results) {
      return urls;
    })

    /*if(this.props.wbt.modules !== undefined) {
      var modules = this.props.wbt.modules.map((module) => {
        var elements = module.elements.map((element) => {
          if(element.type == 'audio' || element.type == 'instruction') {
            return fetch('https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/get/attachments?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                filter: {
                  _id: element.settings.audio
                },
              })
            }).then(response => response.json()).then(function(results) {
              var src = CmsHelper.getDirectUrl({
                file: results.entries[0].file.path
              })
              return urls[element.id + '_audio'] = src;
            })
          } else if(element.type == 'image') {
            return fetch('https://globalrailacademy.fp-eauthor.fp-server.com/cms/api/collections/get/attachments?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                filter: {
                  _id: element.settings.image
                },
              })
            }).then(response => response.json()).then(function(results) {
              var src = CmsHelper.getDirectUrl({
                file: results.entries[0].file.path
              })
              return urls[element.id + '_image'] = src;
            })
          }
        })
        return Promise.all(elements).then(function(results) {
        })
      })
    }
    return Promise.all(modules).then(function(results) {
      return urls;
    })*/
  }

  async getScormFile(name, folder) {
    var promise = await new Promise(async function (resolve, reject) {
      await request(process.env.REACT_APP_STATIC_URL + '/scorm/' + folder + name, function (error, response, body) {
        if(error) {
          reject(error);
        } else {
          resolve(body);
        }
      });
      return true;
    });
    return promise;
  }

  handleChangeFormat(e) {
    this.setState({
      format: e.target.value
    });
  }

  render() {
    var title = this.props.settings.title
    var exportFormat = this.state.format ?  this.state.format : '';
    var exportLabel = 'Exportieren';
    if(exportFormat == 'fp-lms') {
      exportLabel = 'In LMS übertragen';
    }
    return(
      <div className="modal-window export">
        <div className="headline">Exportieren</div>
        <div className="main">
          {
            !this.state.exported ?
              <form
                className="form"
                onSubmit={this.handleExportWbt.bind(this)}
              >
                <div className="field">
                  <div className="category">WBT Titel</div>
                  <input
                    type="text"
                    ref={this.title}
                    defaultValue={title}
                    placeholder="WBT Titel"
                  />
                </div>
                <div className="fields">
                  <div className="field">
                    <div className="category">Format</div>
                    <div className="select-wrapper">
                      <select
                        ref={this.format}
                        onChange={this.handleChangeFormat.bind(this)}
                      >
                        {
                          this.exportFormats.map((format, index) => {
                            return (
                              <option
                                value={format.value}
                                key={index}
                              >
                                {format.label}
                              </option>
                            )
                          })
                        }
                      </select>
                      <div className="arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.835" height="4.771" viewBox="0 0 8.835 4.771">
                          <path d="M3138.31,281.57l4.241,4.241,4.241-4.241" transform="translate(-3138.133 -281.394)" fill="none" stroke="#142847" strokeWidth="0.5" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.format == 'fp-lms' ?
                      <div className="field">
                        <div className="category">Dauer</div>
                        <input
                          type="text"
                          ref={this.duration}
                          placeholder="Dauer"
                        />
                      </div>
                    :
                      ''
                  }
                </div>
                <button
                  className="button highlighted round"
                >
                  <span className="label">{exportLabel}</span>
                </button>
              </form>
            :
              <div>Das WBT wurde erfolgreich in das LMS exportiert!</div>
          }
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
